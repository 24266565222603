.core-tab {
  flex-grow: 1;
  .MuiAppBar-colorPrimary {
    color: $color-dark-blue;
    background-color: #ffffff;
    .MuiTab-wrapper {
      text-transform: capitalize;
      font-weight: 600;
    }
    .PrivateTabIndicator-colorSecondary {
      background-color: $color-dark-blue;
    }
  }
}

.MuiTabs-flexContainer {
  .MuiTab-textColorInherit.Mui-selected {
    white-space: nowrap;
    color: #0019d8;
  }
  .MuiTab-textColorInherit {
    color: #212121;
    white-space: nowrap;
  }
}
