.login-layout {
    background-size: cover !important;
    background: #212121;
    @media screen and (max-width: 600px) {
        margin: -64px 0px;
    }
    //   background: #212121;
    margin: -88px -24px;
    height: 100vh;
    display: flex;
    align-items: center;
    .auth-box {
        max-width: 400px;
        min-width: 280px;
        margin: 0 auto;
        width: 80vw;
        padding: 30px 12px;
        border-radius: 10px;
        background-color: #00031d91;
        height: fit-content;
        .page-title {
            font-size: 18px;
            font-weight: 600;
            text-align: center;
            display: block;
            margin: 32px 0px 0px;
            color: white;
        }
        .logo {
            width: 48vw;
            max-width: 250px;
            display: block;
            margin: 0 auto;
        }
        .logo-title {
            color: #ffb11f;
            font-size: 14px;
            font-weight: 600;
            margin-right: 28px;
            margin-top: -2px;
            display: block;
            text-align: right;
        }
        .forget-link {
            color: rgba(255, 255, 255, 0.54);
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            text-align: right;
            display: block;
            cursor: pointer;
            flex: 1;
        }
        .form-box {
            margin-top: 40px;
            .MuiFormControl-root {
                width: 100%;
                margin-bottom: 16px;
                .MuiFormLabel-root {
                    color: rgba(255, 255, 255, 0.54);
                }
                .MuiInputBase-root {
                    color: rgba(255, 255, 255, 0.87);
                }
                .MuiInput-underline:before {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.42);
                }
                .MuiIconButton-root {
                    color: rgba(255, 255, 255, 0.54);
                }
            }
            .other-auth {
                display: flex;
                align-items: center;
                .MuiCheckbox-root {
                    color: rgba(255, 255, 255, 0.54);
                }
                .MuiFormControlLabel-label {
                    color: rgba(255, 255, 255, 0.54);
                    font-size: 12px;
                }
            }
        }
        .login-btn {
            &.MuiButton-root {
                border-radius: 100px;
                margin: 24px auto 0px;
                width: 100%;
                width: 180px;
                display: block;
                text-transform: capitalize;
            }
        }
    }
}