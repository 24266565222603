div.subheader {
    flex-grow: 1;
    margin: 0;
    position: relative;
    list-style: none;
    margin-bottom: 0;
    border-radius: 0;
    cursor: auto;
    padding: 8px 12px;
    width: 100%;
    display: flex;
    position: relative;
    box-sizing: border-box;
    text-align: left;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    justify-content: flex-start;
    text-decoration: none;
    .MuiListItemText-primary,
    .MuiListItemText-secondary {
        display: flex;
        align-items: center;
        color: unset;
        flex-wrap: wrap;
    }
    .indicator {
        font-size: 16px;
        margin-right: 2px;
    }
    .flex-space {
        flex: 1;
    }
    .MuiListItemAvatar-root {
        min-width: 50px;
    }
    img.avatar-svg {
        padding: 5px;
        width: 70%;
        height: auto;
    }
    .MuiAvatar-colorDefault {
        background-color: transparent;
    }
    .MuiAvatar-root.avatarProj {
        border-radius: 10%;
        width: 50px;
        margin-right: 10px;
    }
    .MuiAvatar-root.avatarSm {
        width: 30px;
        width: 30px;
    }
    .list-badge span {
        color: #ff1818;
        box-shadow: 0 0 0 2px #fff;
        background-color: #ff1818;
    }
    .list-badge.list-badge-no span {
        color: #fff;
    }
    img.avatar-project {
        max-width: 100%;
        height: 100%;
        object-fit: cover !important;
    }
    img.avatar-img {
        max-width: 100%;
        height: 100%;
        object-fit: cover !important;
    }
    .list-badge .MuiBadge-anchorOriginBottomRightCircle {
        right: 18%;
        top: -14%;
        margin: -26px -49px 12px 0px;
    }
    .list-badge-no span.MuiBadge-badge {
        height: 12px;
        padding: 4px;
        font-size: 8px;
        min-width: 14px;
        font-weight: 600;
        border-radius: 10px;
        padding-top: 5px;
    }
    .list-badge-no .MuiBadge-anchorOriginTopRightRectangle {
        right: 20%;
        top: 6%;
        margin: -20px -49px 12px 0px;
    }
}

.subheader.subheader-bg-dark {
    background: #BFD3FF;
}

.subheader.subheader-bg-light {
    background: #E5EDFF;
}

.subheader-shadow {
    -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(0, 0, 0, 0.05), 0px 3px 4px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(0, 0, 0, 0.05), 0px 3px 4px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(0, 0, 0, 0.05), 0px 3px 4px 0px rgba(0, 0, 0, 0.05);
    ;
}