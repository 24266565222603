.float-btn {
  position: fixed !important;
  right: 24px;
  bottom: 24px;
  background: linear-gradient(to bottom, #2f47ff, #2408ba);
  .MuiFab-label {
    color: #fff;
  }
  &.with-footer {
    bottom: 68px;
  }
  &.with-float-amt {
    bottom: 100px;
  }
}

.float-img-upload {
  label {
    width: 45px;
    height: 45px;
    position: absolute;
    border-radius: 100px;
    opacity: 0;
    cursor: pointer;
  }
  &.MuiButtonBase-root {
    position: absolute;
    left: 65px;
    right: 0;
    margin: 75px auto 0px;
  }
}

button.MuiButtonBase-root.MuiButton-root.btn-subheader-action {
  background-color: #0019d8;
  color: white;
  font-size: 12px;
  font-weight: 600;
  float: right;
}

button.MuiButton-root {
  text-transform: initial;
}

.mega-option {
  min-width: 80px;
  .MuiSvgIcon-root {
    display: block;
    width: 16px;
    margin: 0 auto;
  }
  .MuiIconButton-label {
    justify-content: start;
  }
  .icon {
    border: 1px solid #0019d8;
    width: 100%;
    border-radius: 3px;
    padding: 6px 8px;
  }
  .icon-selected {
    width: 100%;
    border: 1px solid #0019d8;
    background-color: #0019d8;
    color: #fff;
    border-radius: 3px;
    padding: 6px 8px;
  }
}
