.calendar div span.MuiPickersCalendarHeader-dayLabel {
  color: #ffffff;
  margin: 0 8.5px;
}

.calendar {
  background-image: url('../../images/calendar-bg.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #212121;
  width: 100%;
}

.calendar
  div
  p.MuiTypography-root.MuiTypography-body1.MuiTypography-alignCenter {
  font-weight: 600;
  color: white;
}

.calendar div .MuiPickersCalendarHeader-iconButton {
  z-index: 1;
  background-color: transparent;
  color: white;
}

.calendar
  button
  span
  p.MuiTypography-root.MuiTypography-body2.MuiTypography-colorInherit {
  font-size: 14px;
  font-weight: 500;
  color: white;
}

.calendar div button.MuiPickersDay-day {
  color: white;
  margin: 0 8.5px;
}

.calendar div button.MuiPickersDay-daySelected {
  color: #ffffff;
  font-weight: 700;
  background-color: #f1a600;
}

.calendar div.MuiPickersCalendarHeader-switchHeader {
  padding-top: 20px;
  margin-top: 0px;
}

.circle.MuiIconButton-root {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin: 0 2px;
  padding: 0;
  font-size: 0.75rem;
  margin: 0px 8.5px;
}

.hrx.calendar div button.MuiPickersDay-daySelected {
  color: #ffffff;
  font-weight: 700;
  background-color: #b4cef2;
}

.orange-dot {
  position: absolute;
  height: 0;
  width: 0;
  border: 2px solid;
  border-radius: 50%;
  border-color: #f1a600;
  right: 50%;
  transform: translateX(1px);
  top: 100%;
}
.hrx {
  .green
    button
    span
    p.MuiTypography-root.MuiTypography-body2.MuiTypography-colorInherit {
    font-size: 14px;
    font-weight: 500;
    color: #b2fc74;
  }
}
