.core-list {
  //Typography
  .title {
    line-height: 22px;
  }

  .smTitle {
    line-height: 20px;
  }

  .xsTitle {
    line-height: 18px;
  }

  .xxTitle {
    line-height: 16px;
  }

  .desc {
    line-height: 16px;
    color: #454545;
  }

  .mdDesc {
    line-height: 18px;
  }

  &.subheader {
    padding-bottom: 0px;
    &.single {
      .MuiListItemAvatar-root {
        margin-left: -6px;
      }
      .MuiListItem-root {
        .MuiAvatar-root {
          width: 24px;
          height: 24px;
          margin: 0 auto;
        }
      }
    }
    .MuiListItem-root {
      background: #bfd3ff;
      margin-bottom: 0px;
      cursor: unset;
      border-radius: unset;
    }
  }
  &.subheader-set {
    padding-bottom: 0px;
    .MuiListItem-root {
      cursor: unset;
      background: #e5edff;
      margin-bottom: 0px;
      border-radius: unset;
    }
  }
  &.MuiList-padding {
    padding-top: 0px;
  }
  .MuiListItemIcon-root {
    min-width: unset;
  }

  .MuiCheckbox-root {
    padding: 0px;
    margin: -2px 12px -2px -2px;
  }

  .extra-row {
    display: flex;
    overflow: hidden;
    align-items: center;
    width: 100%;
  }
  .MuiListItem-root.bg-blue-light {
    background: #e5edff;
  }
  .MuiListItem-root.bg-blue-dark {
    background: #bfd3ff;
  }
  .MuiListItem-root {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0),
      0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background: #fff;
    margin-bottom: 4px;
    cursor: pointer;
    padding: 12px;
    flex-wrap: wrap;
    .avatar-svg {
      img {
        padding: 0px;
        width: 100%;
        height: 100%;
      }
      border-radius: unset;
    }
    .MuiAvatar-root {
      width: 32px;
      height: 32px;
    }
    .MuiAvatar-colorDefault {
      background-color: unset;
    }
    .avatar-img {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .avatarProj {
      border-radius: 10%;
      width: 50px;
      background: #212121;
      margin-right: 12px;
      img {
        margin-right: 0px;
        width: 100%;
        object-fit: cover;
      }
      .avatar-project {
        width: 100%;
        object-fit: cover;
      }
    }
    .list-badge span {
      color: #ff1818;
      box-shadow: 0 0 0 2px #fff;
      background-color: #ff1818;
    }
    .list-badge .MuiBadge-anchorOriginBottomRightCircle {
      right: 18%;
      top: -14%;
      margin: -26px -49px 12px 0px;
    }
    .list-badge-no span.MuiBadge-badge {
      height: 14px;
      padding: 4px;
      font-size: 8px;
      min-width: 14px;
      font-weight: 600;
      border-radius: 10px;
      padding-top: 3px;
    }
    .list-badge-no .MuiBadge-anchorOriginTopRightRectangle {
      right: 20%;
      top: 6%;
    }
    .MuiListItemText-multiline {
      margin: 0px;
      flex: 1;
    }
    .MuiListItemText-primary,
    .MuiListItemText-secondary {
      display: flex;
      align-items: center;
      color: unset;
      flex-wrap: wrap;
    }
    .indicator {
      font-size: 16px;
      margin-right: 2px;
    }
    .flex-space {
      flex: 1;
    }
    .MuiListItemAvatar-root {
      min-width: 46px;
    }
    .list-badge.list-badge-no span {
      color: #fff;
    }
  }
  .MuiListItem-secondaryAction {
    padding-right: 50px;
  }
  .MuiIconButton-edgeEnd {
    padding: 0px;
    margin-right: 0px;
    color: #212121;
  }
  .MuiListItem-root.Mui-selected {
    background-color: #d1d1d1;
  }
}
