@import './typography.scss';
@import '../components/button.scss';
.MuiDialogTitle-root.dialog-title {
  background-color: #bfd3ff;
  padding: 8px 12px;
}

.MuiDialogContent-root.dialog-content {
  padding: 8px 12px 12px;
}

.MuiDialogActions-root.dialog-footer {
  padding: 8px 12px;
}

.MuiTypography-body1.dialog-title {
  @extend .fs-16, .fw-semibold, .text-overflow;
}

.MuiTypography-body1.dialog-subtitle {
  @extend .fs-12, .fw-medium, .text-overflow;
}

button.MuiButtonBase-root.MuiButton-root.btn-dialog {
  color: white;
  font-size: 12px;
  font-weight: 600;
  float: right;
}

button.MuiButtonBase-root.MuiButton-root.btn-reset {
  background-color: #ffffff;
  color: #0019d8;
  font-size: 12px;
  font-weight: 600;
  float: right;
  box-shadow: none;
  border: solid 1px #0019d8;
  padding: 0px 7px 0px 4px;
}

svg.MuiSvgIcon-root.dialog-reset-icon {
  width: 16px;
  padding-right: 3px;
  margin-top: 0px;
}

.dialog-content {
  .card-form {
    box-shadow: unset;
    margin: -8px -12px 0px;
  }
}
